import apiService from "@/services/ApiService";
import { ContactBookingFormData, ContactFormData, ResponseObject } from "@/types";


export const apiPostContact = async (data: ContactFormData): Promise<ResponseObject<null>> => {

	const response = await apiService.post(`/contact`, data);

	return response;
}

export const apiPostContactBooking = async (data: ContactBookingFormData): Promise<ResponseObject<null>> => {

	const response = await apiService.post(`/contact-booking`, data);

	return response;
}