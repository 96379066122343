import { BASE_URL } from "@/constant";
import { getSession } from "@/session";
import { ResponseObject } from "@/types";



interface ParamsObj {
	[key: string]: any;
}

interface GetRequest {
	(url: string, queryParams?: ParamsObj | undefined, controller?: AbortController): Promise<ResponseObject<any>>
}
interface PostRequest {
	(url: string, bodyParams: ParamsObj, queryParams?: ParamsObj | undefined): Promise<ResponseObject<any>>
}
interface DeleteRequest {
	(url: string): Promise<ResponseObject<any>>
}


class ApiService {

	API_ERROR: ResponseObject<null> = {
		status: false,
		data: null,
		message: 'Something went wrong!',
	}

	constructor() {
		console.log('ApiService', BASE_URL)
	}


	get: GetRequest = async (url, queryParams, controller = new AbortController()) => {
		
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const signal = controller.signal;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				signal,
			});
	
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};

	getNoCache: GetRequest = async (url, queryParams, controller = new AbortController()) => {
		
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const signal = controller.signal;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				signal,
				cache: 'no-store'
			});
	
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};
	
	
	post: PostRequest = async (url, bodyParams, queryParams) => {
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(bodyParams),
			});
			
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};
	
	
	authGet: GetRequest = async (url, queryParams) => {
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const session = await getSession();
	
			if (!session) {
				return {...this.API_ERROR};
			}
	
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + session.token,
				},
			});
			
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};
	
	authPost: PostRequest = async (url, bodyParams, queryParams) => {
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const session = await getSession();
	
			if (!session) {
				return {...this.API_ERROR};
			}
	
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + session.token,
				},
				body: JSON.stringify(bodyParams),
			});
			
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};

	postMultipart: PostRequest = async (url, bodyParams, queryParams) => {
		try {
			const apiUrl: string = `${BASE_URL}${url}${this.makeGetParamsString(queryParams)}`;
	
			const session = await getSession();
	
			if (!session) {
				return {...this.API_ERROR};
			}

			const formData = new FormData();
			for (let key in bodyParams) {
				formData.append(key, bodyParams[key]);
			}
	
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2),
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + session.token,
				},
				body: formData,
			});
	
			if (response.ok) {
				const data = await response.json();
	
				return data;
			} else {
				return {...this.API_ERROR};
			}
		} catch (error) {
			return {...this.API_ERROR};
		}
	};


	getToken (): (string | null) {
		try {
			const jsonValue = localStorage.getItem('@apiToken')
			return (jsonValue != null) ? jsonValue : null;
		} catch(e) {
			return null;
		}
	}

	setToken (token: string): boolean {
		try {
			localStorage.setItem('@apiToken', token)
			return true;
		} catch (e) {
			return false;
		}
	}


	private makeGetParamsString (params: ParamsObj | undefined): string {
		if (!params || Object.keys(params).length === 0) {
			return '';
		}

		return '?' +
			Object.keys(params).map(function (key) {
				return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
			}).join('&');
	};
}

const apiService = new ApiService();
export default apiService;