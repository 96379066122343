import apiService from "@/services/ApiService";
import { ResponseObject, Unit, UnitAvailabilityResponse, UnitImages, UnitsList } from "@/types";



type QueryParams = {
	page?: number
	size?: number
}

type MetaData = {
	page_count: number
	page_size: number
	total_items: number
	page: number
}

type UnitsListResponse = {
	metadata: MetaData
	units: UnitsList[]
}

export const apiGetUnitsList = async ({
	page = 1,
	size = 20,
}: QueryParams): Promise<ResponseObject<UnitsListResponse>> => {

	// if (!cacheService.checkIsOnline()) {
	// 	return cacheService.makeOfflineResponse(`@events`);
	// }

	const response = await apiService.get('/units', {
		page,
		size,
	});

	// localStorage.setItem(`@events`, JSON.stringify(response.data));

	return response;
}


export const apiGetAvailableUnitsList = async ({
	page = 1,
	size = 20,
	arrivalDate = null,
	departureDate = null,
}: {
	page?: number
	size?: number
	arrivalDate?: string | null
	departureDate?: string | null
}): Promise<ResponseObject<UnitsListResponse>> => {


	const response = await apiService.get('/available-units', {
		page,
		size,
		arrivalDate,
		departureDate,
	});

	return response;
}


export const apiGetUnitDetails = async (unitId: number | string): Promise<ResponseObject<Unit>> => {

	const response = await apiService.get(`/units/${unitId}`, {});

	return response;
}

export const apiGetUnitImages = async (unitId: number | string): Promise<ResponseObject<UnitImages[]>> => {

	const response = await apiService.get(`/units/${unitId}/images`, {});

	return response;
}


export const apiGetUnitAvailability = async (unitId: number | string, startDate: string, endDate: string, controller: AbortController): Promise<ResponseObject<UnitAvailabilityResponse[]>> => {

	const response = await apiService.get(`/units/${unitId}/availability`, {
		startDate,
		endDate
	}, controller);

	return response;
}
