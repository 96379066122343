"use client"

import Image from "next/image";
import Link from "next/link";
import Carousel from 'react-bootstrap/Carousel';
import { Button, Card, Col, Container, Ratio, Row, Spinner } from 'react-bootstrap';

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBinoculars, faQuoteLeft, faQuoteRight, faRuler, faUser, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { UnitsList } from "@/types";

import image1 from '@/images/home-slider-1.jpeg';

type CardProps = {
	unit: UnitsList
}

export default function ResidenceCard({unit}: CardProps) {
	return (
		<Card>
			<Image src={unit.coverImage} alt={unit.shortName} className="card-img-top" width={500} height={250} quality={50} loading='lazy' />
			<Card.Title>{unit.shortName} {unit.bedrooms}/{ (unit.fullBathrooms + (unit.halfBathrooms / 2)) }</Card.Title>
			<Card.Body style={{ padding: '2rem' }}>
				<Card.Text>
					<FontAwesomeIcon icon={faUser} className="text-primary" /> <span className="text-muted">Capacity:</span> {unit.maxOccupancy} Adults
				</Card.Text>
				<Card.Text>
					<FontAwesomeIcon icon={faBinoculars} className="text-primary" /> <span className="text-muted">View:</span> {unit.name.split('-')[0].trim()}
				</Card.Text>
				<Card.Text>
					<FontAwesomeIcon icon={faRuler} className="text-primary" /> <span className="text-muted">Size:</span> {unit.area} Sqft
				</Card.Text>
				<Link href={`/accommodation/${unit.id}`} className='btn btn-primary'>View Room <FontAwesomeIcon icon={faArrowRight} /></Link>
			</Card.Body>
		</Card>
	);
}
