"use client"

import Image from "next/image";
import Link from "next/link";
import Carousel from 'react-bootstrap/Carousel';
import { Button, Card, Col, Container, Form, Ratio, Row, Spinner } from 'react-bootstrap';
import { redirect } from "next/navigation";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBinoculars, faImages, faQuoteLeft, faQuoteRight, faUser, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import HomeSlider from "@/Components/HomeSlider";
import HomeResidenceSlider from "@/Components/HomeResidenceSlider";
import HomeContactForm from "@/Components/HomeContactForm";

import homeFtImage2 from '@/images/home-slider-1.jpeg';

export default function Home() {

	const reviews = [
		{
			review: 'I would like to thank all the staff for the easy week we had at Ten35 Seaside in Florida. The check-in experience and flexibility throughout the week was great. The place was clean, organized, pretty and also easy to manage. Thank you again and looking forward to return soon.',
			author: 'Thiago Goncalves - ',
		},
		{
			review: 'We wanted to express our sincere gratitude for the opportunity to stay at Ten35 Seaside Rentals property (Unit 408)!! Firstly – The building itself as well as its amenities are wonderful – pool, hot tub, beach access, chairs/umbrellas. The unit itself – excellent! Spacious, modern, clean, nicely decorated.',
			author: 'Robert and Katarina - ',
		},
		{
			review: 'Absolutely stunning property!',
			author: 'Molly - ',
		},
		{
			review: 'Ten35 is our new vacation fave. The property is furnished with high quality accommodations. Very clean. The pool and amenities were properly sized for the building allowing us to enjoy it to ourselves at times. We loved the short walk to private beach front with concierge for chairs and water sports. Marta was very helpful',
			author: 'Gregory - ',
		},
		{
			review: 'My wife and I spent six weeks at “Seaside” during February and March of this year and loved every minute of our stay. All of the staff from the office to maintenance and cleaning were exceptionally helpful and friendly. Our gulf front unit was spacious and well designed for comfort with beautiful furniture and a',
			author: 'Bill Goodine - ',
		},
		{
			review: 'I was told from a friend that has a condo here that the views and location was going to be amazing.. but the heads up did not do it justice.. The sunsets from our balcony were like a dream. The room was extremely updated and well kept as well.',
			author: 'Brett T - ',
		}
	]

	
	return (
		<div className="home-page">
			<HomeSlider />

			<section style={{ backgroundColor: '#ffffff', paddingTop: '4rem', paddingBottom: '4rem' }}>


				<Container className='bg-white homepage-features'>

					<Row className="mt-4">
						<Col md={6}>
							<div className="text-section">
								<h2>Your Dream Vacation Awaits at TEN35 Seaside</h2>
								<p>Relax and unwind at TEN35 Seaside’s spectacular new vacation rentals, where modern luxury meets the natural beauty of Siesta Key. Featuring open floor plans, floor-to-ceiling glass and oversized terraces, residents will delight in enjoying world-class views of the sea, sand and endless sky. With gracious appointments that offer sophisticated, yet family-oriented functionality, these two-, three- and four-bedroom residences offer all you could dream of for the perfect island getaway… and so much more.</p>
								<Link href="/all-rooms" className='btn btn-primary'>View All Rooms</Link>
							</div>
						</Col>
						<Col md={6}>
							<div className="image-wrapper ft-1"></div>
						</Col>
					</Row>
				</Container>

				<section style={{ backgroundColor: '#f2f2f3', paddingTop: '2rem', paddingBottom: '2rem' }}>
					<Container className="homepage-residence-section">
						<h4 className="text-center">Please contact the front desk for assistance by calling this number.</h4>

						<div className="text-center">
							<p style={{ display: 'inline-block', padding: '1rem', border: '1px solid #ccc', borderRadius: '5px', margin: '1rem 0' }}>
								941-925-1035
							</p>
						</div>

					</Container>
				</section>

				<Container className='bg-white homepage-features'>

					<Row className="mt-4">
						<Col md={6}>
							<div className="image-wrapper ft-2 mb-5">
								<Image src={homeFtImage2} alt={`Property Info gallery 1`} className="img-fluid"  />
							</div>

							<Ratio aspectRatio="16x9">
								<iframe title="1035 Seaside dr 601"
									src="https://www.youtube.com/embed/yuBEqufnUFQ?feature=oembed"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
									loading='lazy'></iframe>
							</Ratio>
						</Col>
						<Col md={6}>
							<div className="text-section">
								<h2>Exceptional Amenities and Unmatched Location</h2>
								<p>During your vacation at TEN35 Seaside, take a dip in our beachfront pool and spa, relax poolside in our private cabanas, or gather with friends and family at our gulf-front barbecues and fire pit. Sink your toes into the quartz-white sands of Siesta Key Beach, where our beach valet provides lounge chair and umbrella service complemented by an array of water sport equipment for your entertainment. Looking to venture into town? Our on-site concierge can help secure reservations for local dining, cultural and sporting events, and area activities.</p>
								<Link href="/property-information" className='btn btn-primary'>Property Information</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>


			<section style={{ backgroundColor: '#f2f2f3', paddingTop: '4rem', paddingBottom: '4rem' }}>
				<Container className="homepage-residence-section">
					<h4 className="text-center">Choose Your Residence</h4>
					<p className="text-center text-primary">TEN35 SEASIDE’s residences have been carefully planned with a modern take on beachside living.</p>

					<HomeResidenceSlider />
				</Container>
			</section>


			<section style={{ backgroundColor: '#ffffff' }} className="homepage-gallery-section">
				<Row>
					<Col md={6} className="text-column">
						<div style={{ padding: '4rem' }}>
							<Row>
								<Col md={10}>
									<h4>Property Photos and Information</h4>
									<p>Featuring open floor plans, floor-to-ceiling glass and oversized terraces, residents will delight in enjoying world-class views of the sea, sand and endless sky. With gracious appointments that offer sophisticated, yet family-oriented functionality, these residences will provide the perfect getaway for generations to come</p>

									<Link href="/photo-gallery" className='btn btn-primary mt-4'><FontAwesomeIcon icon={faImages} /> Open Gallery</Link>
								</Col>
							</Row>
						</div>
					</Col>
					<Col md={6} className="image-column"></Col>
				</Row>
			</section>

			<section style={{ backgroundColor: '#7f7f7f', paddingTop: '4rem', paddingBottom: '4rem' }}>
				<Container className="text-center homepage-reviews-section text-white">
					<h3>Reviews</h3>
					<Row>
						<Col sm={2}></Col>
						<Col sm={8}>
							<Carousel controls={false} interval={null}>
								{reviews.map((review, i) => {
									return (
										<Carousel.Item key={i}>
											<div className="text-start">
												<FontAwesomeIcon icon={faQuoteLeft} size="3x" className="text-primary" />
											</div>
											<p>{review.review}</p>
											<div className="text-end">
												<FontAwesomeIcon icon={faQuoteRight} size="3x" className="text-primary" />
											</div>
											<p>{review.author}</p>
										</Carousel.Item>
									)
								})}
							</Carousel>
						</Col>
					</Row>
				</Container>
			</section>

			<section style={{ backgroundColor: '#f2f2f3', paddingTop: '4rem', paddingBottom: '4rem' }}>
				<Container className="homepage-contact-section">
					<HomeContactForm />
				</Container>
			</section>
		</div>
	);
}
