"use client"

import Image from "next/image";
import Carousel from 'react-bootstrap/Carousel';

import image1 from '@/images/home-slider-1.jpeg';
// import image2 from '@/images/home-slider-2.jpeg';
import image3 from '@/images/home-slider-3.jpeg';
import image4 from '@/images/home-slider-4.jpeg';
import image5 from '@/images/home-slider-5.jpeg';
import image6 from '@/images/home-slider-6.jpeg';
import image7 from '@/images/home-slider-7.jpeg';
import image8 from '@/images/home-slider-8.jpeg';
import { useState } from "react";
import { redirect } from "next/navigation";
import Link from "next/link";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import dayjs from "dayjs";
import DateInput from "./DateInput";


const HomeSlider = (): JSX.Element => {
	const [arrivalDate, setArrivalDate] = useState<string>(dayjs().add(1, 'day').format('YYYY-MM-DD'));
	const [departureDate, setDepartureDate] = useState<string>(dayjs().add(8, 'days').format('YYYY-MM-DD'));


	const images = [
		image1,
		// image2,
		image3,
		image4,
		image5,
		image6,
		image7,
		image8,
	]

	return (
		<>
			<div className='homepage-slider'>
			<Carousel fade={true}>
				{images.map((image, i) => {
					return (
						<Carousel.Item key={i} >
							<Image src={image} alt={`Slider ${i}`} className="img-fluid" 
								// width={window.innerWidth}
								// fill={true}
								width={0}
								height={0}
								sizes="100vw"
								quality={60}
								loading='lazy'
							/>
						</Carousel.Item>
					)
				})}
			</Carousel>
			
			
			<Container className='date-section'>
				<Row>
					<Col lg={2}></Col>
					<Col lg={8}>
						<Card>
							<Card.Body>
								<Row className="align-items-center justify-content-center">
									<Col md={4}>
										<Form.Group controlId="arrivalDate">
											<Form.Label className="small text-muted">Arrival Date</Form.Label>
											<DateInput
												value={arrivalDate}
												// max={departureDate}
												onChange={(e) => setArrivalDate(e.target.value)}
											/>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group controlId="departureDate">
											<Form.Label className="small text-muted">Departure Date</Form.Label>
											<DateInput
												value={departureDate}
												min={arrivalDate}
												onChange={(e) => setDepartureDate(e.target.value)}
											/>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Label>&nbsp;</Form.Label>
										<div className="d-grid">
											<Link href={`/all-rooms?arrivalDate=${arrivalDate}&departureDate=${departureDate}`} className="btn btn-primary">Search Availability</Link>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={2}></Col>
				</Row>
			</Container>
			</div>
		</>
	);
}

export default HomeSlider;
