"use client"

import { useState, useEffect } from 'react';



export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState({
		width: 0,
		height: 0,
	});

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		
		setWindowDimensions({
			width,
			height
		})
	}

	useEffect(() => {
		getWindowDimensions()

		window.addEventListener('resize', getWindowDimensions);
		return () => window.removeEventListener('resize', getWindowDimensions);
	}, []);

	return windowDimensions;
}
