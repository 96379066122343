"use client"

import Carousel from 'react-bootstrap/Carousel';
import { Col, Row, Spinner } from 'react-bootstrap';

import { useEffect, useState } from "react";
import { apiGetUnitsList } from "@/api/units";
import { UnitsList } from "@/types";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import ResidenceCard from "@/Components/ResidenceCard";


const HomeResidenceSlider = (): JSX.Element => {

	const [loading, setLoading] = useState<boolean>(true);
	const [units, setUnits] = useState<UnitsList[]>([]);
	const { width } = useWindowDimensions();


	useEffect(() => {
		(async () => {
			const response = await apiGetUnitsList({});

			if (response.status) {
				setLoading(false);
				setUnits(response.data.units)
			}
		})()
	}, [])

	if (loading) {
		return (
			<div className="text-center mt-4">
				<Spinner animation="border" role="status" >
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		)
	}

	return (
		<>
			<Carousel controls={false} interval={6000} variant="dark">
				{(width > 768) ? units.reduce((resultArray: UnitsList[][] | never[], item, index) => {
					const chunkIndex = Math.floor(index / 3)
					if (!resultArray[chunkIndex]) {
						resultArray[chunkIndex] = [] // start a new chunk
					}
					resultArray[chunkIndex].push(item)
					return resultArray
				}, []).map((cols, i) => {
					return (
						<Carousel.Item key={i}>
							<Row>
								{cols.map((unit, j) => {
									return (
										<Col md={4} key={j}>
											<ResidenceCard unit={unit} />
										</Col>
									)
								})}
							</Row>
						</Carousel.Item>
					)
				}) : units.map((unit, i) => {
					return (
						<Carousel.Item key={i}>
							<Row>
								<Col>
									<ResidenceCard unit={unit} />
								</Col>
							</Row>
						</Carousel.Item>
					)
				})}

			</Carousel>
		</>
	)
}

export default HomeResidenceSlider;
