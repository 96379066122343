"use client"

import { Button, Col, Form, FormControlProps, Row } from 'react-bootstrap';
import { useState } from "react";
import dayjs from 'dayjs';

const DateInput = (props: FormControlProps): JSX.Element => {


	return (
		<>
			<Form.Control
				type="date"
				{...props}
				data-date={(props.value) ? dayjs(props.value as string).format('MM/DD/YYYY') : 'MM/DD/YYYYY'}
				className={(props.className) ? `${props.className} custom-date-input` : `custom-date-input`}
			/>
		</>
	)
}

export default DateInput;